<template>
  <main class="p-3 px-4 container">
    <section class="d-flex flex-column align-items-center">
      <div class="text-muted-light strong-header-text font-weight-300 my-3">
        Do you have good WiFi network coverage set up in your residence?
      </div>
      <img
        :src="require('/src/assets/images/home-wifi.png')"
        alt="home-wifi"
        class="mb-5 mt-3 wifi-coverage-page__img"
      />
      <v-radio-group v-model="answer" row>
        <v-radio
          v-for="option in ['Yes', 'No']"
          color="#fff"
          class="me-5"
          dark
          :key="option"
          :value="option"
        >
          <template #label>
            <span class="ms-1 text-muted-light body-text">{{ option }}</span>
          </template>
        </v-radio>
      </v-radio-group>
    </section>
  </main>
</template>

<script>
export default {
  name: "WifiCoverage",

  data() {
    return {
      answer: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.wifi-coverage-page {
  ::v-deep &__radio {
    width: 2em;
    height: 2em;
  }

  &__img {
    max-width: 90%;
  }
}
</style>
